import React,{useState} from 'react'
import { Container,Row,Col,Image ,Button,Form} from 'react-bootstrap'
import TopHearder from './TopHearder'
import img from "../Image/login.png"

export default function Login() {

    const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };


  return (
    <>
    <TopHearder/>
    <br></br>
    <Container style={{background:"#fff",borderRadius:10}}>
        <Row>
            <Col md={6}>
               <Image src={img} style={{width:400,marginBottom:20}} className="mx-auto d-block"/>
            </Col>
            <Col md={6}>
               <h3 style={{textAlign:"center",marginTop:100}}>Login Here</h3>

<Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="validationCustom01" className="mx-auto d-block">
        
          <Form.Control
            required
            type="text"
            placeholder="Enter Email Address"
            style={{borderRadius:20}}
          />
         
        </Form.Group>
      
      
      </Row>
     
      <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="validationCustom01" className="mx-auto d-block">
        
          <Form.Control
            required
            type="password"
            placeholder="Enter Password"
            style={{borderRadius:20}}
            
          />
         
        </Form.Group>
      
      
      </Row>
   
      <Button type="submit" className="mx-auto d-block" style={{fontFamily:"Dm sans"}}>Login Now</Button>
    </Form>
            </Col>
        </Row>

    </Container>
    
    </>
  )
}
