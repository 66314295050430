import React from 'react'
import Home from './Home';
import Feature from './Feature';
import Banner from './Banner';
import Connect from './Connect';
import TopHearder from './TopHearder';
import Footer from './Footer';
import BottomNavbar from './BottomNavbar';

export default function AllPage() {
  return (
    <>
      <h1 style={{ marginTop: 50, fontSize: 25, textAlign: 'center' }}>Website is down!!!</h1>
      {/* <TopHearder />
      <Home />
      <br></br>
      <Feature />
      <br></br>
      <Banner />
      <br></br>
      <Connect />
      <br></br>
      <BottomNavbar />
      <br></br>
      <Footer /> */}
    </>
  )
}
