import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import "../componentCss/Font.css"
import "../componentCss/topHeader.css"
import Logo from '../Image/logo.png'

export default function TopHearder() {
  return (
    <>
      <Container style={{ background: "#1e90ff" }} fluid>
        <Row>
          <Col md={2}>
            <Image src={Logo} className="logoClass" />
          </Col>
          <Col md={10}>
            <h1 className="logoTitleClass">EBM India</h1>
          </Col>
        </Row>
      </Container>

    </>
  )
}
