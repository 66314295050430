import logo from './logo.svg';
import './App.css';
import Home from './component/Home';
import Feature from './component/Feature';
import Banner from './component/Banner';
import Connect from './component/Connect';
import TopHearder from './component/TopHearder';
import Footer from './component/Footer';
import AllPage from './component/AllPage';
import Login from './component/Login';

import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';


function App() {
  return (
    <>
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<AllPage />} />
          <Route path="/login" element={<Login />} />
          </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
